<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="收款单号" prop="shouKuanDH" >
                <el-input v-model="form.shouKuanDH" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="客户名称" prop="keHuMC" >
                <el-input v-model="form.keHuMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="退货单号" prop="tuiHuoDH" >
                <el-input v-model="form.tuiHuoDH" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="金额" prop="jinE" >
                <el-input v-model="form.jinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="收款方式" prop="shouKuanFS" >
                <el-input v-model="form.shouKuanFS" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="银行卡号" prop="yinXingKaHao" >
                <el-input v-model="form.yinXingKaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="256" show-word-limit/>
            </el-form-item>
            <el-form-item label="收款时间" prop="shouKuanSJ">
                <el-date-picker v-model="form.shouKuanSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="操作时间" prop="caoZuoSJ">
                <el-date-picker v-model="form.caoZuoSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="操作人" prop="caoZuoRen" >
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/zjgl/TuiHuoZiJinLiuShui";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                shouKuanDH: [], // 收款单号
                keHuMC: [], // 客户名称
                tuiHuoDH: [], // 退货单号
                jinE: [], // 金额
                shouKuanFS: [], // 收款方式
                yinXingKaHao: [], // 银行卡号
                beiZhu: [], // 备注
                shouKuanSJ: [], // 收款时间
                caoZuoSJ: [], // 操作时间
                caoZuoRen: [], // 操作人
            }
            this.titlePrefix = '退货资金流水';
            this.defaultForm = {
                id: null,
                shouKuanDH: "", // 收款单号
                keHuMC: "", // 客户名称
                tuiHuoDH: "", // 退货单号
                jinE: "", // 金额
                shouKuanFS: "", // 收款方式
                yinXingKaHao: "", // 银行卡号
                beiZhu: "", // 备注
                shouKuanSJ: "", // 收款时间
                caoZuoSJ: "", // 操作时间
                caoZuoRen: "", // 操作人
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>