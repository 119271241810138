<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="退货单号" >
                <el-input v-model="search.tuiHuoDH" size="small" />
            </x-search-item>
            <x-search-item label="操作时间" textWidth="400px">
                <x-date-between separator="~" :start.sync="search.createTimeBegin" :end.sync="search.createTimeEnd"
                                type="datetimerange" size="small"/>
            </x-search-item>
            <x-search-item label="收款方式" >
                <x-selector-one v-model="search.shouKuanFS" size="small" dictType="T_COLLECTION"></x-selector-one>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="退货资金流水导出">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="shouKuanDH" label="收款单号" />
            <el-table-column width="180" prop="keHuMC" label="客户名称" />
            <el-table-column width="180" prop="tuiHuoDH" label="退货单号" />
            <el-table-column width="180" prop="jinE" label="金额" />
            <el-table-column width="180" prop="shouKuanFS" label="收款方式" >
                <x-dict-show slot-scope="{row}" :code="row.shouKuanFS" dictType="T_COLLECTION" />
            </el-table-column>
            <el-table-column width="180" prop="kaiHuYinHang" label="开户银行" />
            <el-table-column width="180" prop="yinXingKaHao" label="银行卡号" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="shouKuanSJ" label="收款时间" />
            <el-table-column width="180" prop="caoZuoSJ" label="操作时间" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/zjgl/TuiHuoZiJinLiuShui";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/zjgl/thysgl/TuiHuoZiJinLiuShuiEdit";
    import Detail from "@/view/zjgl/thysgl/TuiHuoZiJinLiuShuiDetail";
    import {deepCopy} from "@/util/objects";
    import {DICT_TYPE_COLLECTION} from "@/util/constant";

    export default {
        name: "TuiHuoZiJinLiuShuiList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    tuiHuoDH: '',
                    shouKuanFS: '',
                    createTimeBegin:"",
                    createTimeEnd:"",
                },
                headAndKey: {
                    shouKuanDH: "收款单号",
                    keHuMC: "客户名称",
                    tuiHuoDH: "退货单号",
                    jinE: "金额",
                    shouKuanFS: "收款方式",
                    kaiHuYinHang: "开户银行",
                    yinXingKaHao: "银行卡号",
                    beiZhu: "备注",
                    shouKuanSJ: "收款时间",
                    caoZuoSJ: "操作时间",
                    caoZuoRen: "操作人",
                },
                exportTableData:[],
                condition: [],
            }
        },
        methods:{
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    if(this.table.data && this.table.data.length>0){
                        this.exportTableData = deepCopy(this.table.data)
                        this.exportTableData.forEach(item=>{
                            if(item.shouKuanFS===DICT_TYPE_COLLECTION.YHK.key){
                                item.shouKuanFS=DICT_TYPE_COLLECTION.YHK.name
                            }if(item.shouKuanFS===DICT_TYPE_COLLECTION.WX.key){
                                item.shouKuanFS=DICT_TYPE_COLLECTION.WX.name
                            }if(item.shouKuanFS===DICT_TYPE_COLLECTION.XX.key){
                                item.shouKuanFS=DICT_TYPE_COLLECTION.XX.name
                            }else{
                                item.shouKuanFS=DICT_TYPE_COLLECTION.ZFB.name
                            }
                        })
                    }
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>
</style>
