<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="shouKuanDH" label="收款单号" />
            <x-detail-item prop="keHuMC" label="客户名称" />
            <x-detail-item prop="tuiHuoDH" label="退货单号" />
            <x-detail-item prop="jinE" label="金额" />
            <x-detail-item prop="shouKuanFS" label="收款方式" >
                <x-dict-show :code="entity.shouKuanFS" dictType="T_COLLECTION"/>
            </x-detail-item>
            <x-detail-item prop="yinXingKaHao" label="银行卡号" />
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item prop="shouKuanSJ" label="收款时间" />
            <x-detail-item prop="caoZuoSJ" label="操作时间" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/zjgl/TuiHuoZiJinLiuShui";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                shouKuanDH: "", // 收款单号
                keHuMC: "", // 客户名称
                tuiHuoDH: "", // 退货单号
                jinE: "", // 金额
                shouKuanFS: "", // 收款方式
                yinXingKaHao: "", // 银行卡号
                beiZhu: "", // 备注
                shouKuanSJ: "", // 收款时间
                caoZuoSJ: "", // 操作时间
                caoZuoRen: "", // 操作人
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>